/* src/components/header/header.module.scss */
.header {
  background-color: #2C3E50 !important; /* Solid color */
  --Paper-overlay: none !important;
  height: 4rem;
  padding-left: 6px !important;
}

.spacer {
  flex-grow: 1;
}

.logo {
  height: 2rem;
}

@media (max-width: 768px) {
  .logo {
    height: 1.5rem;
  }
}
