body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}



/* src/styles/global.scss */

::-webkit-scrollbar {
  width: 2px; /* Thin scrollbar */
}

::-webkit-scrollbar-track {
  background: transparent; /* No background */
}

::-webkit-scrollbar-thumb {
  background: rgba(0, 0, 0, 0.5); /* 50% opaque */
  border-radius: 1px; /* Thin line */
}

/* For Firefox */
* {
  scrollbar-width: thin;
  scrollbar-color: rgba(255, 255, 255, 0.3) transparent; /* 50% opaque thumb, no background */
}
