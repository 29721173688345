/* src/components/sidebar/sidebar.module.scss */

.footer {
  text-align: center;
  padding-bottom: 10px;
  font-size: 12px;
  opacity: 0.5;
  color: #fff;
}

.drawer {
  flex-shrink: 0;
  white-space: nowrap;
}

.drawerPaper {
  overflow: hidden !important;
  width: 240px;
  max-width: 240px;
  margin-top: 4rem;
  height: calc(100% - 4rem) !important; /* Adjust according to header height */
  transition: width 0.3s ease;
  background: linear-gradient(to bottom, #2C3E50, #1ABC9C) !important;
  z-index: 2 !important;
  color: #fff !important;
}

.drawerPaper svg {
  color: #fff;
}

.collapsed {
  width: 72px;
  max-width: 72px;
}

.topIcons {
  margin-bottom: auto; /* Pushes the bottom icons to the bottom */
  overflow: auto;
}

.bottomIcons {
  margin-top: auto; /* Pushes the bottom icons to the bottom */
}

.menuItem {
  display: flex;
  align-items: center;
}

.menuText {
  margin-left: 16px;
  width: 100%;
}

.avatar {
  width: 1.7rem !important;
  height: 1.7rem !important;
  margin: 0 0 0 0 !important;
  padding: 0 0 0 0 !important;
}

.userDetails {
  width: 1rem;
  display: flex;
  flex-direction: column;
  margin-left: 16px;
}

.topIcons {
  overflow-x: hidden; /* Prevent horizontal scroll */
  overflow-y: auto;
}

.submenuPopup {
  padding-left: 0 !important;
}

.submenuItemExpanded {
  padding-left: 48px !important;
}

.highlight {
  background-color: rgba(255,255,255,0.2) !important;
}

.subMenuPanel {
  width: 240px;
  background: #f4f4f4; // or use theme-based colors
  color: #000;
  display: flex;
  flex-direction: column;
  overflow: hidden;
}

.subMenuHeader {
  text-align: center;
  padding: 16px;
}

.subMenuHeaderText {
  margin-bottom: 8px;
}

.subMenuList {
  flex: 1;
  overflow-y: auto;
  padding: 0 8px;
}

