/* src/components/layouts/LoginLayout.module.scss */
@import '../../styles/common';

.loginLayout {
  @extend .backgroundGradient;
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.footer {
  text-align: center;
  padding-bottom: 10px;
  font-size: 12px;
  opacity: 0.5;
  color: #fff;
}
