/* src/components/Login/Login.module.scss */

.loginContainer {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 20px;
}

.logo {
  width: 20%;
  max-width: 200px;
  min-width: 100px;
  margin-bottom: 20px;
}

.formContainer {
  background-color: rgba(255, 255, 255, 0.9);
  padding: 30px;
  border-radius: 8px;
  text-align: center;
  width: 90%;
  max-width: 400px;
}

.divider {
  margin: 1rem 0 !important;
}

@media (max-width: 600px) {
  .logo {
    width: 30%;
  }
}

.formContainer h6 {
  font-size: 1.5rem;
}

.formContainer button {
  width: 100%;
  margin-top: 10px;
}

#firebaseui-auth-container {
  width: 100%;
}

.firebaseui-idp-button {
  width: 100% !important;
  margin-bottom: 10px !important;
}

.loginContainer {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 20px;
}

.spinner {
  width: 50px;
  height: 50px;
  border: 5px solid #ffffff;
  border-top: 5px solid transparent;
  border-radius: 50%;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}
