/* src/styles/toastStyles.css */

.Toastify__toast--dark {
  background: #424242; /* Customize the background color for dark theme */
}

.Toastify__toast--light {
  background: #fff; /* Customize the background color for light theme */
}

/* Customize text color, icons, etc., as needed */
