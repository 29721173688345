/* src/components/layouts/MainLayout.module.scss */
.mainLayout {
  display: flex;
  flex-direction: column;
  height: 100vh;
}

.body {
  display: flex;
  flex: 1;
  overflow: hidden;
  margin-top: 64px; /* Adjust according to header height */
}

.content {
  flex: 1;
  overflow-y: auto;
  padding: 16px;
  margin-left: 240px; /* Adjust according to expanded sidebar width */
  transition: margin-left 0.3s ease;
}

.sidebarCollapsed .content {
  margin-left: 72px; /* Adjust according to collapsed sidebar width */
}
