.root {
  z-index: 1 !important;
}

.paper {
  display: flex !important;
  flex-direction: column !important;
  overflow-y: hidden !important;
  top: 4rem !important;
  height: calc(100% - 4rem) !important; /* Adjust according to header height */
  transition: width 0.3s ease !important;
  z-index: 2 !important;
  padding: 0;
}

.heading {
  padding: 1rem;
  flex-shrink: 0;
  text-align: center;
  font-weight: bold;
  border-bottom: dotted 2px currentColor;
}

.content {
  padding: 1rem;
  flex: 1;
  overflow-y: auto;
}

.footer {
  padding: 1rem;
  flex-shrink: 0;
  border-top: dotted 2px currentColor;
}

@media (max-width: 768px) {
  .paper {
    left: 71px !important;
  }
}
